@import url(https://unpkg.com/leaflet@1.6.0/dist/leaflet.css);

@import url(https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css);

@import '@coreui/coreui/dist/css/coreui.min.css';

@font-face{
  font-family: "Light";   /*Can be any text*/
  src: local("NeoSansProLight"),
    url("../fonts/NeoSansProLight.OTF") format("truetype");
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-left: 3px;
  border: 3px solid;
}

.green {
  background-color: rgb(93, 222, 166);
  border-color: rgb(31, 208, 130);
}

.yellow {
  background-color: rgb(243, 222, 148);
  border-color: rgb(246, 208, 77);
}

.red {
  background-color: rgb(255, 181, 144);
  border-color: rgb(252, 118, 52);
}

.blue {
  background-color: rgb(153, 161, 255);
  border-color: rgb(47, 62, 234);
}

.leaflet-container {
    width: 100%;
    height: 100vh;
  }
  
body {
  font-family: "Light";
    margin: 0;
    padding: 0;
    max-height: 100vh;
    overflow-y: hidden;
}

.dropdown-toggle::after {
  display: none !important; 
}

.leaflet-control {
  position: relative;
  top: 10px;
  left: 10px;
  margin: 0px !important;
}

.dropdown-toggle {

  position: relative;
  top: 10px;
  left: 0px;
  z-index: 0 !important;
  box-shadow: none;
  background-color:#FFFFFF !important; 
  color:#000000 !important;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, .2) !important;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  max-height: 34px;
  max-width: 34px;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.dropdown-toggle:hover {
  background:#f4f4f4 !important; 
  border-color: rgb(177, 177, 177) !important;
}

.dropdown-button-logo {
  max-width:100%;
  max-height:100%;
  object-fit: contain;
}

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  background:#f4f4f4 !important; 
  border-color: rgb(177, 177, 177) !important;
}

button:active {outline: none !important;}

.dropdown-menu {
  min-width: fit-content;
  white-space: nowrap;
  padding: 3%;
}

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}